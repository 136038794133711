/* 外部方法 */
import { computed, ref, watch } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import {
  pickBy,
  identity,
  cloneDeep,
  countBy,
  isUndefined,
  includes,
  isEqual,
  isNull,
  isEmpty,
  pick,
  sortBy
} from 'lodash-es';

/* 內部方法 */
import useDesk from '@sms/common/composables/useDesk';
import useAiLogger from '../../composables/useAiLogger';
import useDeskStore from './desk';
import useFlagMapStore from '@sms/common/store/flagMap';

/* 型別 */
import BlankResult from '@sms/common/models/BlankResult';
import GameResultSet from '@sms/common/models/GameResultSet';
import type AIServerResponse from '@sms/common/interfaces/AIServerResponse';

/** AI 伺服器對每張牌的辨識結果（正常牌面不計；如 6s, Ad 等等） */
enum ExceptPiontResults {
  Card_Back = 'back',
  Null = 'null'
}

type GameTypeCode = 'BAC' | 'BJB' | 'DRT' | 'SIB' | 'ROU';

export type PredictState = 'none' | 'rolling' | 'predicting' | 'success' | 'fail';

export default defineStore('ai', () => {
  /* 桌資料相關 */
  const deskStore = useDeskStore();
  const { readonlyDeskData } = storeToRefs(deskStore);
  const { flagMapGroupList } = useFlagMapStore();
  const desk = useDesk(readonlyDeskData, flagMapGroupList);
  const gameType = computed(() => desk.deskData.value.GameTypeCode as GameTypeCode);
  const gameMode = computed(() => desk.deskData.value.GameModeCode);

  /* 預設值 */
  const BLANKRESULT = new BlankResult();
  const POSITIONLIST = Object.keys(new BlankResult()) as (keyof BlankResult)[];

  /* 校正模式相關 */
  /** 是否正在校正 */
  const isCalibrating = ref(false);

  /** 校正模式AI辨識結果 */
  const aiCalibrateResult = ref<Record<string, AIServerResponse>>({});

  /**  重置校正用辨識結果 */
  const resetAICalibrateResult = () => {
    aiCalibrateResult.value = {};
  };

  /** 設定校正用辨識結果 */
  const setAICalibrateResult = (result: Record<string, AIServerResponse>) => {
    resetAICalibrateResult();
    Object.assign(aiCalibrateResult.value, result);
  };

  /** 開關校正模式 */
  const toggleCalibrate = () => {
    isCalibrating.value = desk.isPausing && !isCalibrating.value;
  };

  /* 只有暫停時可以開啟校正模式 */
  watch(desk.isPausing, (isPausing) => {
    if (!isPausing) isCalibrating.value = false;
  });

  /* 辨識相關 */
  /** 辨識狀態 */
  const predictState = ref<PredictState>('rolling');
  const predictTimes = ref(0);

  /** 輪盤狀態 */
  const isBallVaild = ref(false);

  /** 百家樂補莊閒的時候如果補錯牌這裡會提示true/false */
  const isWrongPosition = ref(false);

  /** 暫存目前截圖與資料 */
  const currentScreenshot = ref<string | undefined>('');

  /** 輪盤或骰寶是否已經觸發結算 */
  const isAISettleFinish = ref(false);

  const shouldCountdownPredict = computed(
    () =>
      ['BAC', 'BJB', 'DRT'].includes(gameType.value) &&
      desk.isCountdown.value &&
      desk.countdownLength.value - desk.countdownSeconds.value >= 10
  );

  /** 根據遊戲狀態判斷是否執行AI辨識 */
  const shouldAiPredict = computed(() => {
    return (
      ((shouldCountdownPredict.value || desk.isDealing.value) && !desk.isPausing.value && !isAISettleFinish.value) ||
      isCalibrating.value
    );
  });

  /** 使否觸發硬體裝置 */
  const isTriggerHardware = computed(() => desk.currentGameResult.value.R00 !== '0');

  /** sampling次數 */
  const SAMPLING_TIMES = ref(2);

  /** 暫存牌位置，加速辨識用 */
  const referenceFourPoker = ref<AIServerResponse[]>([]);

  /** AI 將本局辨識結果暫存 */
  const rawAIPredictedResult = ref<BlankResult[]>([]);

  /** AI 將本局AI原始資料暫存 */
  const rawAIPredictedData = ref<AIServerResponse[][]>([]);

  /** AI 最新一次辨識結果暫存 */
  const latestAIPredictedResult = ref<BlankResult>(cloneDeep(BLANKRESULT));

  /** AI辨識結果 */
  const aiPredictedResult = ref(cloneDeep(BLANKRESULT));

  /** 分析共用函式 */
  const isObjectOverlap = (obj1: AIServerResponse, obj2: AIServerResponse) => {
    const isOverlapX = obj1.xmin <= obj2.xmax && obj1.xmax >= obj2.xmin;
    const isOverlapY = obj1.ymin <= obj2.ymax && obj1.ymax >= obj2.ymin;
    return isOverlapX && isOverlapY;
  };

  /** 辨識結果 */
  const getAiPredictedResult = (num: number) => {
    const latestResult = latestAIPredictedResult.value;
    const result = aiPredictedResult.value;
    const keys = Object.keys(latestResult) as (keyof typeof latestResult)[];
    const predictedResult = {} as BlankResult;
    const predictedAiResult = {} as BlankResult;

    for (let i = 0; i < num; i++) {
      const key = keys[i];
      predictedResult[key] = latestResult[key];
      predictedAiResult[key] = result[key];
    }

    return {
      latestAIPredictedResult: predictedResult,
      aiPredictedResult: predictedAiResult
    };
  };

  const aiOnePredictedResult = computed(() => getAiPredictedResult(1));
  const aiTwoPredictedResult = computed(() => getAiPredictedResult(2));
  const aiThreePredictedResult = computed(() => getAiPredictedResult(3));
  const aiSixPredictedResult = computed(() => getAiPredictedResult(6));

  /** 設定辨識狀態 */
  const setPredictState = (state: PredictState) => {
    predictState.value = state;
  };

  const predictTimesIncrease = () => {
    predictTimes.value++;
  };

  const resetPredictTimes = () => {
    predictTimes.value = 0;
  };

  /** 設定百家樂補莊閒的時候是否補錯牌 */
  const setWrongPosition = (value: boolean) => {
    isWrongPosition.value = value;
  };

  /** 儲存當下截圖 */
  const setCurrentScreenshot = (analysisResult: BlankResult, latestScreenshot: string) => {
    /** 比對分析結果跟上次輸出結果 */
    const isSameResult = POSITIONLIST.every((key) => {
      switch (true) {
        case analysisResult[key] === aiPredictedResult.value[key]:
        case isNull(aiPredictedResult.value[key]):
          return true;
        case isNull(analysisResult[key]) && !isNull(aiPredictedResult.value[key]):
        default:
          return false;
      }
    });

    /* 如果沒有暫存圖片或是比對分析結果跟上次輸出結果相同時，暫存截圖 */
    if (isSameResult || isEmpty(currentScreenshot.value)) {
      currentScreenshot.value = latestScreenshot;
    }
  };

  /** 設定辨識結果 */
  const setAIPredictedResult = (result: BlankResult) => {
    if (
      !(
        desk.isDealing.value ||
        (['BAC', 'BJB', 'DRT'].includes(gameType.value) && !desk.isPausing.value && shouldAiPredict.value)
      )
    )
      return;
    rawAIPredictedResult.value.push(result);
    if (rawAIPredictedResult.value.length >= 100) rawAIPredictedResult.value.shift();
  };

  /** 暫存 AI 原始資料 */
  const setAIPredictedData = (data: AIServerResponse[]) => {
    if (!desk.isDealing.value) return;
    rawAIPredictedData.value.push(data);
    if (rawAIPredictedData.value.length >= 300) rawAIPredictedData.value.shift();
  };

  /** 設定最新一次辨識結果 */
  const setLatestAIPredictedResult = (result: BlankResult) => {
    if (!desk.isDealing.value) return;
    Object.assign(latestAIPredictedResult.value, result);
  };

  /** 重置暫存牌 */
  const resetReferenceFourPoker = () => {
    referenceFourPoker.value.length = 0;
  };

  /** 取得乾淨的AI結果 */
  const getCleanResult = () => cloneDeep(BLANKRESULT);

  /** 重置辨識結果 */
  const resetAIPredictedResult = () => {
    aiPredictedResult.value = getCleanResult();
    latestAIPredictedResult.value = getCleanResult();
    rawAIPredictedResult.value.length = 0;
    rawAIPredictedData.value.length = 0;
  };

  /** 統計各位置目前的結果 */
  const filteredAIRecognizedResult = computed(
    () =>
      Object.fromEntries(
        Object.keys(BLANKRESULT).map((position) => {
          // 統計目前位置判斷出來的所有結果
          const cardPointStatistics = countBy(rawAIPredictedResult.value, position);

          // 把統計結果中，點數以外的結果次數壓低比sampling次數低一次，避免延遲開牌
          Object.values(ExceptPiontResults).forEach((exceptPiont) => {
            cardPointStatistics[exceptPiont] = isUndefined(cardPointStatistics[exceptPiont])
              ? 0
              : Math.min(cardPointStatistics[exceptPiont], SAMPLING_TIMES.value - 1);
          });

          // 取出目前位置統計結果中，出現率最高的點數及該點數出現次數
          const [point, quantity] = Object.entries(cardPointStatistics).reduce((prev, next) =>
            prev[1] > next[1] ? prev : next
          );

          // 避免誤判，點數出現sampling次數時才顯示
          let resultCardPoint: string | null = quantity >= SAMPLING_TIMES.value ? point : null;

          // 點數以外的結果替換成null
          resultCardPoint = includes(Object.values(ExceptPiontResults), resultCardPoint) ? null : resultCardPoint;
          return [position, resultCardPoint];
        })
      ) as { [key in keyof BlankResult]: string | null }
  );

  /* AI預測結果儲存至伺服器 */
  const { recordStartPredict, recordEndPredict, saveScreenshot, isThisRoundNeedToFeedback } = useAiLogger(
    desk,
    aiPredictedResult
  );

  const statisticsFilter = (data: AIServerResponse[], samplingTime: number) => {
    const tempStatistics = countBy(data, 'result');

    Object.values(ExceptPiontResults).forEach((exceptPiont) => {
      tempStatistics[exceptPiont] = isUndefined(tempStatistics[exceptPiont])
        ? 0
        : Math.min(tempStatistics[exceptPiont], 1);
    });

    // 取出目前位置統計結果中，出現率最高的點數及該點數出現次數
    const [point, quantity] = Object.entries(tempStatistics).reduce((prev, next) => (prev[1] > next[1] ? prev : next));

    // 避免誤判，點數出現 10 次數時才顯示
    const resultCardPoint: string | null = quantity >= samplingTime ? point : null;

    return resultCardPoint;
  };

  /* 判斷各位置是否有資料變動 */
  watch(filteredAIRecognizedResult, (currentResult, previousResult) => {
    // 牌面狀態有變更且不是全空時，才發送辨識結果
    if (isEqual({ ...currentResult }, { ...BLANKRESULT })) return;
    if (isEqual(currentResult, previousResult)) return;

    if (!['BAC', 'BJB'].includes(gameType.value) || referenceFourPoker.value.length < 4) {
      Object.assign(aiPredictedResult.value, pickBy(currentResult, identity));
      return;
    }

    const replaceResult = cloneDeep(currentResult);

    const topFourPosition = ['R04', 'R03', 'R02', 'R01'];

    topFourPosition.forEach((position, index) => {
      const checkPosition = position as keyof BlankResult;

      if (currentResult[checkPosition] !== null) return;

      const sortedReferencePoker = sortBy(referenceFourPoker.value, (poker) => poker.xmin);

      const referencePoker = sortedReferencePoker[index];

      const tempResult: AIServerResponse[] = [];
      // 取後150個
      rawAIPredictedData.value.slice(-150).forEach((data) => {
        const temp = data.filter((item) => isObjectOverlap(item, referencePoker));
        tempResult.push(...temp);
      });

      const filterResult = statisticsFilter(tempResult, 10);

      // prettier-ignore
      const fileterList: (string|null)[] = ['Js','Qs','Ks','Jh','Qh','Kh','Jd','Qd','Kd','Jc','Qc','Kc'];

      replaceResult[checkPosition] = fileterList.includes(filterResult) ? filterResult : null;
    });

    Object.assign(aiPredictedResult.value, pickBy(replaceResult, identity));
  });

  /* 局數更新時清空AI辨識快取結果 */
  watch([() => desk.deskData.value.Round, () => desk.deskData.value.Shoe], () => {
    resetAIPredictedResult();
    resetReferenceFourPoker();
    currentScreenshot.value = '';
    isAISettleFinish.value = false;
    isBallVaild.value = false;
    resetPredictTimes();
  });

  /* 暫停結束後要清除暫停的結果 */
  watch(desk.isPausing, () => {
    resetAIPredictedResult();
    resetAICalibrateResult();
    if (desk.isDealing.value && !desk.isPausing.value && predictTimes.value < 2) {
      setPredictState('none');
      isAISettleFinish.value = false;
      isBallVaild.value = false;
    }
  });

  /**
   * 如果結算時比對，最新結果於送出結果不同，則需要回傳結果
   */
  watch(desk.isSettleFinish, (isSettleFinish) => {
    if (!isSettleFinish) return;
    let comparePosition: string[] = [];
    let gameResultSource: 'GameResultSets' | 'GameResultSecondSets' = 'GameResultSecondSets';

    switch (gameType.value) {
      case 'SIB':
        comparePosition = ['R01', 'R02', 'R03'];
        break;
      case 'ROU':
        comparePosition = ['R01'];
        break;
      case 'DRT':
        comparePosition = ['R01', 'R02'];
        break;
      case 'BAC':
      case 'BJB':
        comparePosition = ['R01', 'R02', 'R03', 'R04', 'R05', 'R06'];
        gameResultSource = 'GameResultSets';
        break;
      default:
        comparePosition = [];
        break;
    }

    const currentGameResult = pick(
      desk.deskData.value[gameResultSource].find(
        (gameResult) => gameResult.Round === desk.deskData.value.Round || new GameResultSet()
      ),
      comparePosition
    );
    const currentAIResult = pick(latestAIPredictedResult.value, comparePosition);

    if (gameType.value === 'SIB') {
      const { R01, R02, R03 } = currentAIResult;

      const sortedResult = [R01, R02, R03].sort((a, b) => {
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        if (a === b) return 0;
        return a > b ? 1 : -1;
      });

      currentAIResult.R01 = sortedResult[0];
      currentAIResult.R02 = sortedResult[1];
      currentAIResult.R03 = sortedResult[2];
    }

    if (!isEqual({ ...currentAIResult }, { ...currentGameResult })) {
      isThisRoundNeedToFeedback.value = true;
    }
  });

  /**
   * 監聽rawAIPredictedData內資料，如果其中有信心水準低於0.93的資料超過30筆，則要上傳log
   */
  watch(
    rawAIPredictedData,
    (data) => {
      if (!['BAC', 'BJB', 'DRT'].includes(gameType.value)) return;
      let lowConfidenceCount = 0;

      data.forEach((items) => {
        items.forEach((item) => {
          if (item.conf < 0.93 && item.result !== 'back') lowConfidenceCount++;
        });
      });

      if (lowConfidenceCount >= 30) {
        isThisRoundNeedToFeedback.value = true;
      }
    },
    {
      deep: true
    }
  );

  return {
    desk,
    gameType,
    gameMode,
    predictState,
    isCalibrating,
    isWrongPosition,
    currentScreenshot,
    shouldAiPredict,
    isTriggerHardware,
    referenceFourPoker,
    aiOnePredictedResult,
    aiTwoPredictedResult,
    aiThreePredictedResult,
    aiSixPredictedResult,
    aiCalibrateResult,
    SAMPLING_TIMES,
    isAISettleFinish,
    aiPredictedResult,
    isBallVaild,
    predictTimes,
    shouldCountdownPredict,
    resetPredictTimes,
    toggleCalibrate,
    setAICalibrateResult,
    resetAICalibrateResult,
    setPredictState,
    setWrongPosition,
    setCurrentScreenshot,
    getCleanResult,
    isObjectOverlap,
    setAIPredictedResult,
    setAIPredictedData,
    setLatestAIPredictedResult,
    resetAIPredictedResult,
    predictTimesIncrease,
    /* 以下是 Logger 相關*/
    recordStartPredict,
    recordEndPredict,
    saveScreenshot
  };
});
