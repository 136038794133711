<template>
  <div class="flex items-center h-full w-full divide-x border-t">
    <div class="flex-shrink-0 px-2">
      <span class="inline-block rounded-full h-3 w-3 mr-2" :class="mediaServerStatus ? 'bg-green-500' : 'bg-red-500'" />
      <span>{{ t('Common.MediaServer') }}</span>
    </div>
    <div :title="statusText" class="flex-1 px-2 min-w-0 overflow-hidden whitespace-nowrap text-ellipsis">
      {{ statusText }}
    </div>

    <div class="ml-auto flex-shrink-0 px-2 flex items-center gap-x-2">
      <div class="rounded-full w-3 h-3 transition-colors" :class="socketStateClass" />
      {{ socketState ?? 'Unconnected' }}
    </div>
    <div class="flex-shrink-0 px-2" v-show="appVersion">App: {{ appVersion }}</div>
    <div class="flex-shrink-0 px-2">Web: {{ webVersion }}</div>
    <div class="flex-shrink-0 px-2">{{ currentTime }}</div>
    <div class="flex-shrink-0 px-2">{{ environment }}</div>
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useIntervalFn, useTimeoutFn } from '@vueuse/core';

/* 內部方法 */
import store from '@/store';
import { isDevelopment } from '@sms/common/utils/helper';
import useI18n from '@sms/common/composables/useI18n';
import useClock from '@sms/common/composables/useClock';
import useGetBuildInfo from '@sms/common/composables/useGetBuildInfo';

const { t } = useI18n();
const { date, time } = useClock();
const { VITE_BUILD_VERSION } = useGetBuildInfo();
const { socketState, isSocketConnected, isSocketConnecting, isSocketDisconnecting, isSocketReconnecting } = storeToRefs(
  store.useSocketStore()
);

const socketStateClass = computed(() => {
  if (isSocketConnected.value) return 'bg-green-400';
  if (isSocketConnecting.value) return 'bg-green-800';
  if (isSocketReconnecting.value) return 'bg-yellow-400';
  if (isSocketDisconnecting.value) return 'bg-gray-400';
  return 'bg-gray-600';
});

const currentTime = computed(() => `${date.value} ${time.value}`);
const webVersion = VITE_BUILD_VERSION;
const appVersion = ref('');
const environment = import.meta.env.VITE_BUILDENV;

const appVersionTimer = useIntervalFn(
  async () => {
    if (!window.getAppVer) return;
    appVersionTimer.pause();

    try {
      const { version } = await window.getAppVer();
      appVersion.value = version;

      document.title += `: ${version}`;
    } catch (error) {
      if (isDevelopment) console.warn('App is not ready');
      appVersionTimer.resume();
    }
  },
  100,
  { immediate: true }
);

// 十秒後不偵測
setTimeout(appVersionTimer.pause, 10000);

// 取得 Media Server 狀態
const mediaServerStatus = ref(true);
const mediaServerChecker = useTimeoutFn(
  async () => {
    try {
      if (!window.checkServerStatus) throw new Error('');
      mediaServerStatus.value = await window.checkServerStatus(
        window.location.href,
        import.meta.env.VITE_SRS_HOST || ''
      );
    } catch (error) {
      mediaServerStatus.value = false;
    } finally {
      mediaServerChecker.start();
    }
  },
  5000,
  { immediate: true }
);

/* 文字狀態 */
const statusTexts = computed(() => {
  const msgs: string[] = [];

  if (!mediaServerStatus.value) {
    msgs.push(t('Error.MediaServer_Down'));
  }

  if (msgs.length === 0) {
    msgs.push(t('Error.StatusOK'));
  }

  return msgs;
});

const statusIndex = ref(0);
useIntervalFn(
  () => {
    statusIndex.value = (statusIndex.value + 1) % statusTexts.value.length;
  },
  5000,
  { immediate: true, immediateCallback: true }
);

const statusText = computed(() => statusTexts.value[statusIndex.value]);
</script>
