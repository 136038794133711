/* 外部方法 */
import { computed, readonly, ref, watch, onBeforeUnmount } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { isEmpty, sortBy } from 'lodash-es';

/* 內部方法 */
import useI18n from './useI18n';

/* 型別 */
import type { Ref, DeepReadonly } from 'vue';
import type DeskSet from '../models/DeskSet';
import type { FlagMapGroupList } from '../store/flagMap';
import GameResultSet from '../models/GameResultSet';

export type UseDeskType = ReturnType<typeof useDesk>;

interface Options {
  gameResultSource: 'GameResultSets' | 'GameResultSecondSets';
}

export default function useDesk(
  originDeskData: DeepReadonly<Ref<DeskSet>>,
  flagMapGroupList: FlagMapGroupList,
  options: Partial<Options> = {}
) {
  const { gameResultSource = 'GameResultSecondSets' } = options;

  const deskData = originDeskData;

  const { PauseStatus, SettlingType, WarningStatus, GameStatus, GameResultType } = flagMapGroupList;

  const { t } = useI18n();

  /** 當局遊戲資料 */
  const currentGameResult = computed<GameResultSet>(
    () =>
      deskData.value[gameResultSource].find((gameResult) => gameResult.Round === deskData.value.Round) ||
      new GameResultSet()
  );

  const gameResultSets = computed<Readonly<GameResultSet[]>>(() => deskData.value[gameResultSource]);
  const lastThreeGameResultSets = computed(() => gameResultSets.value.slice(-4, -1));
  const sortedGameResultSets = computed(() => sortBy(gameResultSets.value, 'Round').reverse());

  const parsedWinflag = computed(() => {
    const winFlag = currentGameResult.value.WinFlag;
    return winFlag && JSON.parse(winFlag);
  });

  /**
   * 是否正在顯示贏家
   *
   * 此處的狀態介於 Settling ~ NewGame 之間
   */
  const isShowingWinner = computed(() => !!currentGameResult.value.WinFlag);

  /* 遊戲狀態 Game Status */
  /*
   * 下方四個遊戲狀態來自後端欄位 GameStatusCode，主要的功用為「引導」目前遊戲流程中前端**可進行的下一步**
   * 表示的並非「目前」正在進行的遊戲狀態，而是「接下來」可進行的遊戲狀態，這點須注意。
   *
   * 舉例來說，當此處的狀態為 Settling 時，表示的是「接下來可以結算（所以此時應該剛發完牌）」，而非結算中或結算完畢，
   * 往後在與後端對接新的遊戲狀態時，請提醒後端保持此行為的一致性。
   */

  const isNewGame = computed(() => deskData.value.GameStatusCode === GameStatus.NEWGAME);
  const isCountdown = computed(() => deskData.value.GameStatusCode === GameStatus.COUNTDOWN);
  const isDealing = computed(() => deskData.value.GameStatusCode === GameStatus.DEALING);
  const isSettling = computed(() => deskData.value.GameStatusCode === GameStatus.SETTLING);
  const isSettleFinish = computed(() => deskData.value.GameStatusCode === GameStatus.SETTLEFINISH);

  /**
   * 是否需要放大發牌介面
   *
   * 此判斷的需求來自規格書 1.2.1 C.
   */
  const shouldHiddingCards = computed(() => isNewGame.value || isCountdown.value);

  const shouldEnlargeScreen = computed(() => isDealing.value || isSettling.value || !!currentGameResult.value.WinFlag);

  /** 是否已結算 */
  const isSettled = computed(() => !isEmpty(currentGameResult.value.WinFlag));

  /** 自動結算 */
  const isAutoSettling = computed(() => deskData.value.SettlingTypeCode === SettlingType.AUTO);

  /*
   * 桌狀態
   */

  /** 第一局 */
  const isFirstRound = computed(() => deskData.value.Round === 0);

  /** 最後一局 */
  const isLastRound = computed(() => deskData.value.IsLastRound);

  /** PB 開桌狀態 */
  const isPBLoggedIn = computed(() => deskData.value.IsLogin);

  /** 荷官登入狀態 */
  const isDealerLoggedIn = computed(() => !!deskData.value.MemberNickname);

  /* --------------------------------- */

  /* 桌況：嚴重
   *
   * 嚴重狀態包含所有暫停狀態、未連線（PB 未開桌）、需要荷官以及維護
   */

  /** 斷線 - PB 未開桌 */
  const isDisconnected = computed(() => !isPBLoggedIn.value);

  /** 需要荷官 - PB 已開桌但荷官未登入視為需要荷官 */
  const isDealerRequired = computed(() => isPBLoggedIn.value && !isDealerLoggedIn.value);

  /** 維護中 */
  const isMaintenance = computed(() => deskData.value.PauseStatusCode === PauseStatus.MAINTENANCE);

  /** 暫停 */
  const isPausing = computed(() => deskData.value.PauseStatusCode !== PauseStatus.NORMAL);

  /** 暫停：換靴 */
  const isPausingWithChangeShoe = computed(() => deskData.value.PauseStatusCode === PauseStatus.CHANGESHOE);

  /** 暫停：等待簽核 */
  const isPausingWithPending = computed(() => deskData.value.PauseStatusCode === PauseStatus.PENDING);

  /** 暫停：簽核 */
  const isPausingWithApproval = computed(() => {
    const { PENDING, PENDING_SUCCESS, PENDING_REJECT } = PauseStatus;
    return [PENDING, PENDING_SUCCESS, PENDING_REJECT].includes(deskData.value.PauseStatusCode);
  });

  /** 暫停：危險牌靴 */
  const isPausingWithRiskShoe = computed(() => deskData.value.PauseStatusCode === PauseStatus.RISK_SHOE);

  /** 暫停：提早開牌 */
  const isPausingWithWrongAction = computed(() => deskData.value.PauseStatusCode === PauseStatus.WRONG_ACTION);

  /** 暫停：維護 */
  const isPausingWithMaintenance = computed(() => deskData.value.PauseStatusCode === PauseStatus.MAINTENANCE);

  /** 嚴重狀態 - 此狀態會包含上述所有嚴重狀態 */
  const isDeskEmergency = computed(
    () => isPausing.value || isDisconnected.value || isDealerRequired.value || isMaintenance.value
  );

  /* --------------------------------- */

  /**
   * 桌況：注意
   *
   * 注意狀態不包含任何暫停狀態，僅作為提示
   */

  /** 警告：協助 */
  const isWarningWithAssistance = computed(() => deskData.value.WarningStatusCode === WarningStatus.ASSISTANCE);

  /** 警告：切牌 */
  // const isWarningWithPrepareCut = computed(() => {});

  /** 警告：更換荷官 */
  // const isWarningWithPrepareDealer = computed(() => {});

  /** 注意狀態 - 此狀態會包含上述所有注意狀態 */
  const isDeskWarning = computed(() => isWarningWithAssistance.value);

  /* --------------------------------- */

  /**
   * 桌況：關閉
   */

  /** 後台關閉且處於維護 */
  const isClosed = computed(() => !deskData.value.Status);

  const isClosedWithMaintenance = computed(
    () => isClosed.value && deskData.value.PauseStatusCode === PauseStatus.MAINTENANCE
  );

  /** 停桌狀態 - 此狀態會包含上述所有關閉狀態 */
  const isDeskDisabled = computed(() => isClosed.value || isClosedWithMaintenance.value);

  /* --------------------------------- */

  /** 桌況文字 */
  const deskStatusText = computed(() => {
    if (isDeskDisabled.value) {
      if (isClosedWithMaintenance.value) return t('Common.Maintenance');
      if (isClosed.value) return t('Common.TableClose');
      return t('Common.Disabled');
    }

    if (isDeskEmergency.value) {
      if (isDisconnected.value) return t('Common.Disconnected');
      if (isDealerRequired.value) return t('Common.Dealer_Required');
      if (isMaintenance.value) return t('Common.Maintenance');
      if (isPausingWithChangeShoe.value) return t('Common.Table_ChangeShoe');
      if (isPausingWithApproval.value) return t('Common.Pending');
      if (isPausing.value) return t('Common.Paused');
      return t('Common.Emergency');
    }

    if (isDeskWarning.value) {
      if (isWarningWithAssistance.value) return t('Common.Assistance');
      return t('Common.Warning');
    }

    return ''; // 正常情況不顯示文字
  });

  /* --------------------------------- */

  /**
   * 按鈕啟用判斷
   */

  /** 換靴中 */
  const isChangeShoe = computed(() => deskData.value.GameStatusCode === GameStatus.CHANGESHOE);

  /** 遊戲進行狀態 */
  const isPlaying = computed(() => deskData.value.Round > 0);

  /** 是否已改牌 ChangeCard = 3 */
  const isChangedCard = computed(() => currentGameResult.value.GameResultTypeCode === GameResultType.ChangeCard);

  /** 是否已經當局取消 Cancel = 1 */
  const isCanceled = computed(() => currentGameResult.value.GameResultTypeCode === GameResultType.Cancel);

  /** 是否可以點擊取消局按鈕 */
  const isCancelRoundClickable = computed(
    () =>
      isDealerLoggedIn.value &&
      isPlaying.value &&
      !(isChangeShoe.value && isPausingWithChangeShoe.value) &&
      !isPausingWithMaintenance.value
  );

  /** 是否可改牌 */
  const isResultChangeModalOpenable = computed(
    () => isPausing.value && (isDealing.value || isSettling.value) && isDealerLoggedIn.value
  );

  /* --------------------------------- */

  /* 計時器相關 */
  const countdownSeconds = ref(0);
  const countdownLength = computed(() => deskData.value.CountdownLength || 0);

  const countdownTimer = useIntervalFn(
    () => {
      if (countdownSeconds.value < 1) countdownTimer.pause();
      else countdownSeconds.value -= 1;
    },
    1000,
    { immediate: false }
  );

  watch(
    deskData,
    ({ Countdown, GameStatusCode }) => {
      if (GameStatusCode === GameStatus.NEWGAME) {
        countdownSeconds.value = Countdown;
      } else if (GameStatusCode === GameStatus.COUNTDOWN) {
        countdownSeconds.value = Countdown;
        countdownTimer.resume();
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    countdownTimer.pause();
  });

  /* R00 狀態
   *
   * 此狀態會應用在 SIB, ROU 此兩個遊戲中
   */
  const processStatusCode = computed(() => currentGameResult.value.R00);

  const processStatus = {
    Unknow: computed(() => processStatusCode.value === '-99'),
    AIInputFailTwice: computed(() => processStatusCode.value === '-2'),
    AIInputFailOnce: computed(() => processStatusCode.value === '-1'),
    Initial: computed(() => processStatusCode.value === '0'),
    Dealed: computed(() => processStatusCode.value === '1'),
    AIInputSuccess: computed(() => processStatusCode.value === '2'),
    DealerEnteredOnce: computed(() => processStatusCode.value === '3'),
    DealerEnteredTwice: computed(() => processStatusCode.value === '4'),
    PBCheckedTrue: computed(() => processStatusCode.value === '5'),
    PbCheckedFalse: computed(() => processStatusCode.value === '6')
  };

  return {
    deskData,
    currentGameResult: readonly(currentGameResult),
    gameResultSets: readonly(gameResultSets),
    lastThreeGameResultSets: readonly(lastThreeGameResultSets),
    sortedGameResultSets: readonly(sortedGameResultSets),

    isPausing,
    isFirstRound,
    isLastRound,
    isAutoSettling,
    isDealerLoggedIn,
    isSettled,
    processStatus,
    processStatusCode,

    countdownSeconds,
    countdownLength,

    isWarningWithAssistance,
    isNewGame,
    isCountdown,
    isDealing,
    isSettling,
    isSettleFinish,
    isChangeShoe,
    isShowingWinner,
    shouldEnlargeScreen,
    parsedWinflag,
    isDeskEmergency,
    isDeskDisabled,
    isDeskWarning,
    isPausingWithPending,
    isPausingWithRiskShoe,
    isPausingWithWrongAction,
    isCancelRoundClickable,
    isClosedWithMaintenance,
    isMaintenance,
    isDisconnected,
    isDealerRequired,
    isChangedCard,
    isCanceled,

    deskStatusText,

    isResultChangeModalOpenable,

    // FIXME: 暫時的解法
    shouldHiddingCards
  };
}
